<template>
  <div class="animated fadeIn">
    <CRow>
    <el-table
            :data="data"
            style="width:100%"
            border
            stripe
            empty-text="暫無數據"
            show-summary
            :summary-method="getSummaries"
          >

           <el-table-column
              type="index"
              fixed
              >
            </el-table-column>
            <el-table-column
                label="單位名稱"
                prop="name"
                width="200px"
            >
            </el-table-column>
            <el-table-column
                label="人數"
                prop="count"
                width="120px"
            >
            </el-table-column>
       
          </el-table>

    </CRow>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import API from '@/api/'
export default {
  name: 'ReportCjxf',
  created () {
      API.account.cjxfSummary().then(res=>{
          this.data = res.data;
      })
  },
  data() {
        return {
          loading: false,
          data:[]
        }
  },
  methods:{
   
  },
  computed: {
      ...mapGetters({
          // 取得資料夾列表
      })
  },
}
</script>

<style scoped>
  .tag_group .el-tag+.el-tag {
    margin-left: 10px;
  }
  .el-transfer >>> .el-transfer__buttons {
    width: 100px;
  }
  .el-transfer >>> .el-transfer__buttons .el-button+.el-button {
    margin-left: 0px;
  }
  .el-table >>> .el-table__body td.warning-cell{
    color:#F56C6C;
  }
</style>
